import { format as formatDate } from "date-fns";
import { mailmagNewContents, footer } from "./mailmag-newcontents"

const mailmagFrameC = function (data: any) {
	const fontcolor = '#333333'
	const bgcolor = '#ffffff'
	const stageBgColor = '#383838'
	const contents = mailmagNewContents({ contents: data.contents, fontcolor: fontcolor })
	const firstview = mailmagNewContents({ contents: data.firstview, fontcolor: "#ffffff" })
	const lankinfo = data.classification[0] == 'ダイヤモンド会員' ?
		{
			'title': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/63f42977bd924df18909cce11415fdf7/diamond.png',
			'info': 'ダイヤモンド会員の皆様へお知らせ',
			'card': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/336b114b38f84245b96519a88c8fb9b5/4.THEClub_Diamond.png'
		}
		: data.classification[0] == 'プラチナ会員' ? {
			'title': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/b4ed258da31148e5bdc33ee072172e46/platinum.png',
			'info': 'プラチナ会員の皆様へお知らせ',
			'card': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/9d19a133374249cc83c23d7e15ef29aa/3.THEClub_Platinum.png'
		} : data.classification[0] == 'ブロンズ会員' ? {
			'title': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/2a9dbb237f5b431fabbf71761b044149/bronze.png',
			'info': 'ブロンズ会員の皆様へお知らせ',
			'card': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/29a47cf00242421b93ea531533f21775/2.THEClub_Bronze.png'
		} : data.classification[0] == 'レギュラー会員' ? {
			'title': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/61e99f5f520a479987ddd0fca55da11c/regular.png',
			'info': 'レギュラー会員の皆様へお知らせ',
			'card': 'https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/be5349b13bf2412f92040241cf0dbae0/1.THEClub_Regular.png'
		} : {
			'title': '',
			'info': '会員の皆様へお知らせ',
			'card': ''
		}
	const memberdate = data.date ? new Date(data.date) : new Date()

	return `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
	<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
	<meta name="viewport" content="width=device-width"/>
	<title>ザ クラブ・メールマガジン</title>
	<style type="text/css">
		body {
			width: 100% !important;
			font-family:'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'Osaka', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
			-webkit-text-size-adjust: 100%; 
			-ms-text-size-adjust: 100%;
			-webkit-font-smoothing: antialiased;
			word-break: break-all;
			margin: 0;
			padding: 0;
		}
		img { 
			line-height: 100%;
			outline: none;
			text-decoration: none;
			display:block;
		}
		a img {
			border: none;
		}
		table {
			border-collapse: collapse;
			color:${fontcolor};
			font-size:16px;
		}
		p{
			margin:0;
			padding:0;
		}

		a.btn01{
			display:inline-block;
			background-color:#867A4A;
			color:#fff;
			text-align:center;
			padding:10px 20px;
			text-decoration:none;
			min-width:150px;
			border-radius:100px;
		}
		
	</style>
</head>
<body>
<table border="0" cellspacing="0" cellpadding="0" width="100%" height="100%" bgcolor="#cccccc">
<tbody>
<tr>
<td align="center" valign="top">
<table width="600" bgcolor="${bgcolor}" border="0" cellspacing="0" cellpadding="0">
<tbody>
<!--メインここから-->
	<tr>
		<td align="center" valign="center" bgcolor="${stageBgColor}">
			<table border="0" cellspacing="0" cellpadding="0" width="100%" style="color:#fff">
				<tbody>
				<tr>
					<td height="15"></td>
				</tr>
				<tr>
					<td class="header" align="center" valign="center">
						<table border="0" cellspacing="0" cellpadding="0" width="100%" >
							<tbody>
								<tr>
									<td width="20"></td>
									<td width="230"></td>
									<td align="center" valign="center" width="100">
									<img src="https://images.microcms-assets.io/assets/319c608d4e9c445a9a79cf6d4f07a336/a9f2d4da6a53486b82060a00d918c5db/mailmag_logo_w.png" alt="THE Club ROYAL PARK HOTELS" border="0" width="90">
									</td>
									<td width="230"></td>
									<td width="20"></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td height="20"></td>
				</tr>
				${lankinfo.title && `<tr>
					<td align="center" valign="top" style="padding-left:30px;padding-right:30px;">
						<img src="${lankinfo.title}" alt="" height="140"/>
					</td>
				</tr>`}
				<tr>
					<td height="20"></td>
				</tr>
				<tr>
					<td align="center" valign="top" style="padding-left:30px;padding-right:30px;">
						<div style="font-weight:bold;font-size:20px;">${lankinfo.info}</div>
					</td>
				</tr>
				<tr>
					<td height="20"></td>
				</tr>
				<tr>
					<td class="rank" align="center" valign="top" style="padding-left:30px;padding-right:30px;">
						<div>いつもロイヤルパークホテルズを<br>ご利用いただき、ありがとうございます。</div>
					</td>
				</tr>
				<tr>
					<td height="20"></td>
				</tr>
				<tr>
					<td align="center" valign="top">
						<table border="0" cellspacing="0" cellpadding="0">
							<tbody>
								<tr>
								${lankinfo.card && `<td width="200">
										<img src="${lankinfo.card}" border="0" style="width:100%;">
									</td>
									<td width="20"></td>`}
									<td width="300" align="center" valign="center" bgcolor="#867A4A" style="color:#fff;padding:10px;">
										<div>${formatDate(memberdate, 'M月d日')}時点、<br />%name%様の保有しているポイントは<br /></div>
										<div style="font-size:20px;font-weight:bold;">%point% points</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td height="30"></td>
				</tr>
				${firstview}
				</tbody>
			</table>
		</td>
	</tr>
	${contents}
<!--メインここまで-->
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</body>
</html>`
}

export default mailmagFrameC
